var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { full: "" } },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("img", {
            attrs: {
              src: require("../../../assets/img/home/nav_icon_close.svg"),
              alt: "",
            },
            on: { click: _vm.close },
          }),
          _c("div", [_vm._v(_vm._s(_vm.titleC))]),
          _c(
            "van-button",
            {
              attrs: {
                type: "info",
                size: "small",
                disabled: !_vm.result.length,
              },
              on: { click: _vm.chainUpload },
            },
            [_vm._v("完成")]
          ),
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          staticClass: "list",
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.itemList, function (item) {
              return _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c("div", [_vm._v(_vm._s(item.timestamp))]),
                _c(
                  "div",
                  {
                    staticClass: "photos",
                    staticStyle: { display: "flex", "flex-wrap": "wrap" },
                  },
                  [
                    _c(
                      "van-checkbox-group",
                      {
                        model: {
                          value: _vm.result,
                          callback: function ($$v) {
                            _vm.result = $$v
                          },
                          expression: "result",
                        },
                      },
                      _vm._l(item.imgs, function (item2, index) {
                        return _c(
                          "div",
                          { staticClass: "photo" },
                          [
                            _c("van-image", {
                              attrs: {
                                src: item2,
                                fit: "cover",
                                alt: "",
                                width: "100%",
                                height: "100%",
                                radius: "6",
                              },
                            }),
                            _c("van-checkbox", {
                              ref: "checkbox",
                              refInFor: true,
                              staticStyle: {
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                              },
                              attrs: { name: item2 },
                              model: {
                                value: item.chooseup[index],
                                callback: function ($$v) {
                                  _vm.$set(item.chooseup, index, $$v)
                                },
                                expression: "item.chooseup[index]",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
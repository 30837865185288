<template>
  <layout full>
    <div class="header">
      <img src="../../../assets/img/home/nav_icon_close.svg" alt="" @click="close">
      <div>{{titleC}}</div>
      <van-button type="info" size="small" @click="chainUpload" :disabled="!result.length">完成</van-button>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in itemList" style="margin-top: 20px">
          <div>{{item.timestamp}}</div>
          <div style="display: flex; flex-wrap: wrap" class="photos">
            <van-checkbox-group v-model="result">
              <div v-for="(item2, index) in item.imgs" class="photo">
                <van-image :src="item2" fit="cover" alt="" width="100%" height="100%" radius="6" />
                <van-checkbox v-model="item.chooseup[index]" :name="item2" ref="checkbox" style="position: absolute; top: 4px; right: 4px"></van-checkbox>
              </div>
            </van-checkbox-group>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </layout>
</template>

<script>
import {List, PullRefresh} from "vant";
import { getMyMetaFileList } from 'api/api'
import common from '@/utils/common'
function formatDate(value) {
  var date = new Date();
  date.setTime(value);
  var month = date.getMonth() + 1;
  if (month < 10)
    month = "0" + month;
  var dateStr = date.getDate();
  if (dateStr < 10)
    dateStr = "0" + dateStr;
  var hours = date.getHours();
  if (hours < 10)
    hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10)
    minutes = "0" + minutes;

  var time = date.getFullYear() + "-" + month + "-" + dateStr +
      " " + hours + ":" + minutes;
  return date.getFullYear() + month + dateStr;
}
function formatDate2(value) {
  var date = new Date();
  date.setTime(value);
  var month = date.getMonth() + 1;
  if (month < 10)
    month = "0" + month;
  var dateStr = date.getDate();
  if (dateStr < 10)
    dateStr = "0" + dateStr;
  var hours = date.getHours();
  if (hours < 10)
    hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10)
    minutes = "0" + minutes;

  var time = date.getFullYear() + "-" + month + "-" + dateStr +
      " " + hours + ":" + minutes;
  return date.getFullYear() + "-" + month + "-" + dateStr;
}

export default {
  name: "chooseup",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      itemList: [],
      page: 1,
      pageSize: 10,
      encrypt: 0,
      result: [],
      title: '链上照片',
      selectResult: []
    }
  },
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  computed: {
    titleC() {
      return this.result.length ? `已选择${this.result.length}个项目`: '已选择0个项目'
    }
  },
  methods: {
    onLoad() {
      let params = {
        metaId: localStorage.getItem('showId'),
        page: String(this.page),
        pageSize: String(this.pageSize),
        timestamp: 0,
        fileType: 1 // 1-Image, 2-Video, 3-Text, 4-Other
      }
      getMyMetaFileList(params).then(res => {
        // this.list = res.data.results.items.map(item => {
        //   return {
        //     resUrl: item.resUrl
        //   }
        // })
        var map = new Map();
        res.data.results.items.forEach((item, key) => {
          //if(item.encrypt != "1") {

          if (map.get(formatDate(item.timestamp)) != undefined) {
            var photosimgArr = map.get(formatDate(item.timestamp))
            var photosimg = new Map();
            photosimg.set('encrypt', item.encrypt)
            photosimg.set('txid', `${process.env.VUE_APP_AppImgApi}/metafile/compress/` + item.txId)
            photosimg.set('publicKey', item.publicKey)
            photosimg.set('txidKey', item.txId)
            photosimg.set('timestamp', formatDate2(item.timestamp))
            photosimg.set('timestamp2', formatDate(item.timestamp))

            photosimgArr.push(photosimg)
            map.set(formatDate(item.timestamp), photosimgArr)
          } else {
            var photosimgArr = [];
            var photosimg = new Map();
            photosimg.set('encrypt', item.encrypt)
            photosimg.set('txid', `${process.env.VUE_APP_AppImgApi}/metafile/compress/` + item.txId)
            photosimg.set('publicKey', item.publicKey)
            photosimg.set('txidKey', item.txId)
            photosimg.set('timestamp', formatDate2(item.timestamp))
            photosimg.set('timestamp2', formatDate(item.timestamp))
            photosimgArr.push(photosimg)
            map.set(formatDate(item.timestamp), photosimgArr)
          }


        })
        console.log("所有图片已经格式化")
        console.log(map)
        var timeListTemp = []
        map.forEach(function(key) {
          key.forEach(function(item) {
            if (timeListTemp.length > 0) {
              var isNew = true
              timeListTemp.forEach(function(k, i) {
                if (k['timestamp'] == item.get('timestamp')) {
                  var photosimg = timeListTemp[i]['imgs']
                  var encryptArr = timeListTemp[i]['encryptArr']
                  var publicKeyArr = timeListTemp[i]['publicKeyArr']
                  var txidKeyArr = timeListTemp[i]['txidKeyArr']
                  photosimg.push(item.get('txid'))
                  encryptArr.push(item.get('encrypt'))
                  publicKeyArr.push(item.get('publicKey'))
                  txidKeyArr.push(item.get('txidKey'))
                  var items = {
                    imgs: photosimg,
                    timestamp: timeListTemp[i]['timestamp'],
                    timestamp2: timeListTemp[i]['timestamp2'],
                    encrypt: timeListTemp[i]['encrypt'],
                    encryptArr: encryptArr,
                    publicKeyArr: publicKeyArr,
                    txidKeyArr: txidKeyArr,
                  }
                  items["chooseup"] = []
                  items.imgs.forEach((i, k) => {
                    items["chooseup"].push(false)
                  })
                  timeListTemp[i] = items
                  isNew = false
                }
              })
              if (isNew) {
                var photosimg = []
                var encryptArr = []
                var publicKeyArr = []
                var txidKeyArr = []
                photosimg.push(item.get('txid'))
                encryptArr.push(item.get('encrypt'))
                publicKeyArr.push(item.get('publicKey'))
                txidKeyArr.push(item.get('txidKey'))
                var items = {
                  imgs: photosimg,
                  timestamp: item.get('timestamp'),
                  timestamp2: item.get('timestamp2'),
                  encrypt: item.get('encrypt'),
                  encryptArr: encryptArr,
                  publicKeyArr: publicKeyArr,
                  txidKeyArr: txidKeyArr,
                }
                items["chooseup"] = []
                items.imgs.forEach((i, k) => {
                  items["chooseup"].push(false)
                })
                timeListTemp.push(items)
              }
            } else {
              var photosimg = []
              var encryptArr = []
              var publicKeyArr = []
              var txidKeyArr = []
              photosimg.push(item.get('txid'))
              encryptArr.push(item.get('encrypt'))
              publicKeyArr.push(item.get('publicKey'))
              txidKeyArr.push(item.get('txidKey'))
              var items = {
                imgs: photosimg,
                timestamp: item.get('timestamp'),
                timestamp2: item.get('timestamp2'),
                encrypt: item.get('encrypt'),
                encryptArr: encryptArr,
                publicKeyArr: publicKeyArr,
                txidKeyArr: txidKeyArr,
              }
              items["chooseup"] = []
              items.imgs.forEach((i, k) => {
                items["chooseup"].push(false)
              })
              debugger
              timeListTemp.push(items)
            }
          })
        })
        console.log("所有照片初始化完成")
        console.log(timeListTemp)
        //uni.setStorageSync('timeList', timeListTemp)
        this.list = timeListTemp;

        // if (res.data.results.items == null || res.data.results.items.length === 0) {
        //   // 加载结束
        //   this.finished = true;
        //   return;
        // }

        // 将新数据与老数据进行合并
        // this.itemList = this.itemList.concat(this.list);

        if (this.refreshing) {
          this.itemList = [];
          this.refreshing = false;
        }

        if (this.list && this.list.length) {
          this.itemList.push(...this.list);
        }

        this.loading = false;
        this.page++
        //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.list.length === 0 || this.itemList.length >= res.total) {
          this.finished = true;
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.page = 0;
      this.onLoad();
    },
    close() {
      this.$router.back()
    },
    chainUpload() {
      // this.selectResult = this.result.map(res => 'metafile://' + res.slice(-64))
      let result = []
      this.result.map(res => {
        result.push({
          url: `${process.env.VUE_APP_AppImgApi}/metafile/${res.slice(-64)}`,
          isImage: true
        })
      })
      this.$emit('selectResult', result)
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 48px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.list {
  padding: 0 15px 50px
}
.photos {
  //div:nth-child(3n+1) {
  //  margin-left: 0px !important;
  //}
  margin-left: -10px;
  .photo {
    width: 108px;
    height: 108px;
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
  }
}
</style>